
  import { Component } from 'vue-property-decorator'
  import Aggregation from './aggregation'

  import { Employee } from '@/entities/hr'
  import { CollapsedItemsOptions, EmployeesBoardOptions } from '@/components/aggregates/index'

@Component({
  components: {
    CollapsedItems: () => import('./CollapsedItems.vue'),
    EmployeeStatus: () => import('./EmployeeStatus.vue'),
  },
})
  export default class EmployeesBoard extends Aggregation {
  declare input: Array<Employee>
  declare options: EmployeesBoardOptions

  setup: Record<string, { icon?: string, color?: string }>
  others: Array<Employee> = []
  assigned: number = 0
  idProcess = null

  async mounted () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'person' } },
    })
    this.idProcess = process[0]?.id
  }

  get employees (): Array<Employee> {
    const { input, options, loading } = this
    if (!input?.length || loading) return []

    const employees = [...input]
    // TODO: remove when selected is actually computed
    employees[0]._selected = true
    this.assigned = employees.map(({ aggregates }) => aggregates.aggregate('assigned').value).reduce((total, count) => total + count)

    this.others = options?.limit ? employees.splice(options.limit) : []
    return employees
  }

  get othersOptions (): CollapsedItemsOptions {
    const { options } = this
    return options?.others || {}
  }
  }
